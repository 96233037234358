import metadata from "../metadata.json" assert { type: "json" };
window.metadata = metadata;
export const boundaryTypes = [
  "sa1_pro",
  "sa1_free",
  "lga",
  "ssc_free",
  "ssc_pro",
];
export const boundaries = ["sa1", "lga", "ssc"];
export const years = ["2018", "2021"];
export const indicators = Object.entries(metadata.indicators).map(([k, v]) => ({
  ...v,
  indicator: k,
}));
export const censusIndicators = Object.entries(metadata.censusIndicators).map(
  ([k, v]) => ({ ...v, indicator: k })
);

export function saveMetadata(key, value, parent) {
  let metadata = {};

  try {
    metadata = fs.readJsonSync("./metadata.json");
  } catch (e) {}

  let base = metadata;
  if (parent) {
    if (!metadata[parent]) {
      metadata[parent] = {};
    }
    base = metadata[parent];
  }
  base[key] = value;

  fs.writeJsonSync(`./metadata.json`, metadata, { spaces: 2 });
}

export const scorecardIndicators = [
  "urban_liveability_index",
  "walkability_index",
  "social_infrastructure_index",
  "transport_percent_dwellings_400m_regular_pt",
  "food_closest_healthy_food",
  "alcohol_number_off_licences_800m",
  "pos_percent_400m_large",
  "employment_percent_working_locally",
  "housing_low_income_housing_stress",
];

export function percentileIndicators(boundaryTypeOrBoundaryTypeYear) {
  const boundaryType = boundaryTypeOrBoundaryTypeYear.replace(/_\d+$/, "");
  return metadata[boundaryType].percentileIndicators;
}

export const scorecardColumns = scorecardIndicators.flatMap((c) => [
  `r_${c}`,
  `p_${c}`,
]);

// const censusDomains = censusIndicators[2021].domains;
export const censusDomains = [
  ...new Set(censusIndicators.map((i) => i.observatory_domain)),
];
export const censusIndicatorsForDomain = (domain) =>
  censusIndicators.filter((i) => i.observatory_domain === domain);

export const domainForCensusIndicator = (indicator) =>
  censusIndicators.find((i) => i.indicator === indicator)?.observatory_domain;

export const toSlug = (x) => x.toLowerCase().replace(/[^a-z09-]/g, "_");

export function tilesetForIndicator(indicator) {
  const d = domainForCensusIndicator(indicator);
  if (d) {
    return `census_${toSlug(d)}`;
  } else {
    return indicator;
  }
}

export function domainForTileset(tileset) {
  if (tileset.startsWith("census_")) {
    return censusDomains.find((d) => toSlug(d) === tileset.slice(7));
  } else {
    throw `No domain for ${tileset}`;
  }
}

export function tilesetForDomain(domain) {
  return `census_${toSlug(domain)}`;
}

export const studyRegions = {
  albury_wodonga: "Albury-Wodonga",
  sydney: "Sydney",
  newcastle_maitland: "Newcastle-Maitland",
  wollongong: "Wollongong",
  goldcoast_tweedheads: "Gold Coast-Tweed Heads",
  ballarat: "Ballarat",
  melbourne: "Melbourne",
  cairns: "Cairns",
  perth: "Perth",
  bendigo: "Bendigo",
  geelong: "Geelong",
  launceston: "Launceston",
  brisbane: "Brisbane",
  mackay: "Mackay",
  sunshine_coast: "Sunshine Coast",
  toowoomba: "Toowoomba",
  townsville: "Townsville",
  adelaide: "Adelaide",
  hobart: "Hobart",
  darwin: "Darwin",
  canberra: "Canberra",
};

export function th(n) {
  if (n === "NA") {
    return n;
  }
  const s = String(n);
  if (s.endsWith("1")) {
    return s + "st";
  } else if (s.endsWith("2")) {
    return s + "nd";
  } else if (s.endsWith("3")) {
    return s + "rd";
  } else {
    return s + "th";
  }
}
