<script setup>
import InfoButton from "@/components/InfoButton.vue";
import allMetadata from "@/metadata.json";
import IndicatorMiniChart from "@/components/IndicatorMiniChart.vue";
import { th } from "@/utils/utils";
</script>

<template lang="pug">
#IndicatorCard.pa2(v-if="boundary" style="border:1px solid #fff; box-shadow: 2px 2px 4px 4px #ccc")

  h3 Selected area
  table.indicator-table(style="width:100%" :class="{ comparison: double, boundaryTypeLGA: boundaryType === 'lga' }")
    tr(v-for="id of tables.identifiers")
      td
      td {{ id[0] }}
      td {{ id[1] }}
      td(v-if="id[2]") {{ id[3] }}

    tr
      td
        InfoButton.info(:text="metadata.metadata")
      td Indicator
      td(:colspan="twoIndicators ? 1 : 2")
        | {{ metadatas[0].observatory_description}}
      td(v-if="double && metadatas[1].observatory_description !== metadatas[0].observatory_description")
        |  {{ metadatas[1].observatory_description}}



    //- table.indicator-table(:class="{ comparison: double, boundaryTypeLGA: boundaryType === 'lga'}")
    tr(v-if="tables.values")
      td
      td {{ tables.values[0] }}
      td {{ tables.values[1] }}
      td {{ tables.values[2] }}
      td(v-if="tables.values[3]") {{ tables.values[3] }}

    template(v-if=" !double")
      tr(v-for="(r, i) of tables.percentiles")
        //- td {{ r }} {{ i}}
        td
          InfoButton.info(v-if="i === 0")
            p Percentile scores enable a comparison of a selected area to other results in the AUO and influence the calculation of deciles shown in the maps. These percentiles can be presented at 3 different levels.
            ul
              li National level percentiles provide a comparison of the selected area, to all cities included in the AUO (at the same level of geography).
              li City level percentiles provide a comparison of the selected area to all areas included in the relevant major city.
              li Local Government level percentiles provide a comparison of the selected area to all areas included in the relevant Local Government Area.
        td {{ r[0] }}
        td {{ r[1] }}
        td(v-if="r[2]") {{ r[2] }}




  w-divider.my4
  .w-flex.mb4
    h3.grow Scorecard
    .no-grow(style="text-align:right" @click="clickPdf")
      w-button(text)
        | Download
        w-icon(xl) mdi mdi-file-pdf-box
  p(v-if="scorecard.length === 0") Zoom in further to see scorecard data.
  div(v-else)

    table#scorecard(v-if="!double")
      tr(v-for="(row, i) of scorecard")
        td
          InfoButton.info(:text="row.infoText" :image="row.icon")
        td
          | {{ row.label }}
          .grey(v-if="row.sub" style="font-size:12px") {{ row.sub }}
        td {{ row.valueFormatted }}
        td(style="width: 200px; max-width: 150px" :astyle="{width: double ? '150px' : '150px' }")
            IndicatorMiniChart(:percentile="row.percentile" acolor="grey" :color="row.color" :first="!i" :percentileth="th(row.percentile)")


  //- div(style="text-align:right")
  //-   a(href="#")
  //-     w-icon() mdi mdi-download
  //-     | Download



</template>

<script>
import allMetadata from "@/metadata.json";
import { colorForIndicatorPercentile, colorByIndicator } from "@/colorRamps";
import makePdf from "@/scorecardPdf";

export default {
  props: ["boundaryType", "indicators", "selectedBoundaries", "twoMaps"],
  data: () => ({ mapZoom: 5 }),
  created() {
    window.IndicatorCard = this;
    window.eventBus.on("map:zoom", (newVal) => {
      this.mapZoom = newVal;
    });
  },
  methods: {
    withUnits(value, units = this.units) {
      if (value === "NA") {
        return value;
      }
      let n = Number(value).toLocaleString();
      if (isNaN(value)) {
        // r_pinch has values like "6. At risk of becoming exclusive".
        n = value;
      }
      if (units === "$") {
        return units + n;
      } else {
        return n + units;
      }
    },
    display(field, which = 0) {
      const units = allMetadata.indicators[field.replace(/^[^_]*_/, "")]?.units;
      const formatted = (val) => this.withUnits(val, units);
      return (
        this.selectedBoundaries[which][field] &&
        String(this.selectedBoundaries[which][field])
          .split(" to ")
          .map(formatted)
          .join(" to ")
      );
    },
    percentileColor(indicator) {
      const value = this.boundary[`p_${indicator}`];
      return colorForIndicatorPercentile(indicator, value);
    },
    indicatorColor(indicator) {
      return colorByIndicator[indicator];
    },
    gradientColor(indicator) {
      const value = this.boundary[`p_${indicator}`];
      const color = colorByIndicator[indicator];
      const steps = [
        [0, color],
        [value, color],
        [+value + 1, "#aaa"],
        [+value + 2, "#ccc"],
        [100, "#fff"],
      ];
      return `linear-gradient(to right, ${steps
        .map(([p, c]) => `${c} ${p}%`)
        .join(", ")})`;
    },
    percentileScale(indicator, flip = false) {
      const value = this.boundary[`p_${indicator}`];
      let adjustedValue = flip ? 100 - value : value;

      return adjustedValue + "px";
    },
    metadataText(field) {
      return allMetadata.indicators[field].metadata;
    },
    percentile(type, which) {
      const p =
        this.selectedBoundaries[which][`${type}_${this.indicators[which]}`];
      return p !== "NA" ? p + "%" : p;
    },
    clickPdf() {
      makePdf({
        selectedBoundaries: this.selectedBoundaries,
        indicators: this.indicators,
        boundary: this.boundary,
        scorecard: this.scorecard,
        metadatas: this.metadatas,

        ...this.tables,
      });
    },
    th(x) {
      return th(x);
    },
  },

  computed: {
    boundary() {
      // TEMP
      return this.selectedBoundaries[0];
    },
    indicator() {
      // TEMP
      return this.indicators[0];
    },
    double() {
      return !!(this.selectedBoundaries[0] && this.selectedBoundaries[1]);
    },
    twoBoundaries() {
      // in the future maybe support two different boundaries at once
      return false;
    },
    twoIndicators() {
      return (
        this.metadatas[0]?.observatory_description !==
        this.metadatas[1]?.observatory_description
      );
    },
    metadata() {
      return allMetadata.indicators[this.indicator];
    },
    metadatas() {
      return this.indicators.map((i) => allMetadata.indicators[i]);
    },
    units() {
      return this.metadata?.units;
    },
    scorecard() {
      return Object.entries(scorecardColumns)
        .map(([field, details]) => ({
          field,
          valueFormatted: this.display(`r_${field}`),
          icon: `scorecard/${field}.png`,
          infoText: this.metadataText(field),
          color: this.indicatorColor(field),
          percentile: this.boundary[`p_${field}`],
          ...details,
        }))
        .filter(
          ({ field }) =>
            this.boundary[`r_${field}`] && this.boundary[`r_${field}`] !== "NA"
        );
    },
    tables() {
      const getIdentifiers = () => {
        const ids = [];
        const addId = (label, idType) => {
          const [id, id2] = [
            this.selectedBoundaries[0][idType],
            this.selectedBoundaries[1]?.[idType],
          ];
          if (!id) return;
          if (id2 && id2 !== id) {
            ids.push([label, id, id2]);
          } else {
            ids.push([label, id]);
          }
        };
        addId("Neighbourhood (SA1)", "sa1");
        addId("Suburb", "suburb");
        addId("LGA", "lga");
        return ids;
      };

      const d = this.double || "";
      const p = (...args) => this.percentile(...args);
      const t = {};

      t.identifiers = getIdentifiers();

      if (this.boundary[`r_${this.indicators[0]}`]) {
        t.values = [
          "Average",
          this.display(`r_${this.indicators[0]}`, 0),
          d && this.display(`r_${this.indicators[1]}`, 1),
        ];
      }

      t.percentiles = [["Percentile", p("p", 0), d && p("p", 1)]];
      if (
        this.boundary[`pl_${this.indicators[0]}`] &&
        this.boundaryType !== "lga"
      )
        t.percentiles.push([
          "Percentile within LGA",
          p("pl", 0),
          d && p("pl", 1),
        ]);
      if (this.boundary[`pc_${this.indicators[0]}`])
        t.percentiles.push([
          "Percentile within city",
          p("pc", 0),
          d && p("pc", 1),
        ]);

      return t;
    },
    // average() {
    //   return this.withUnits(this.boundary[`r_${this.indicator}`]);
    // },
    // average() {
    //   return this.withUnits(this.boundary[`r_${this.indicator}`]);
    // },
  },
  watch: {
    boundary() {
      // TEMP, later add button to trigger
      return 0;
      if (this.boundary) {
        makePdf({
          selectedBoundaries: this.selectedBoundaries,
          indicators: this.indicators,
          boundary: this.boundary,
          scorecard: this.scorecard,
          metadatas: this.metadatas,

          ...this.tables,
        });
      }
    },
  },
};
const scorecardColumns = {
  urban_liveability_index: {
    label: "Liveability",
    sub: "100 is average",
  },
  walkability_index: { label: "Walkability", sub: "0 is average" },
  social_infrastructure_index: {
    label: "Social infrastructure",
    sub: "Out of 16",
  },
  transport_percent_dwellings_400m_regular_pt: {
    label: "Regular public transport access",
    sub: "Within 400m",
  },
  food_closest_healthy_food: {
    label: "Distance to supermarket",
    sub: "",
  },
  alcohol_number_off_licences_800m: {
    label: "Off-licences",
    sub: "Number within 800m",
  },
  pos_percent_400m_large: {
    label: "Large park access",
    sub: "Within 400m",
  },
  employment_percent_working_locally: {
    label: "Local employment",
    sub: "",
  },
  housing_low_income_housing_stress: { label: "Housing stress", sub: "" },
};
</script>

<style scoped>
td {
  padding: 10px;
}
table {
  border-collapse: collapse;
}
</style>
<style>
td {
  /* border: 1px solid #eee; */
}
.bar {
  position: relative;
  display: inline-block;
  border: 1px solid #888;
  height: 1.5em;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px #ccc;
}

.bar:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  /* left: 100%; */
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  /* width: 20px; */
  /* padding: 10px; */
  border-radius: 10px;
  /* background: #000; */
  /* color: #fff; */
  /* text-align: center; */

  display: none; /* hide by default */
  text-shadow: 1px 1px white;
}

.bar:hover:before {
  display: block;
}

td:nth-child(1) {
  width: 10px;
  padding: 0;
  position: relative;
}
td:nth-child(2) {
  width: 160px;
}

.indicator-table.comparison td:nth-child(2) {
  width: 92px;
}

#scorecard td:nth-child(3) {
  width: 100px;
}

.comparison td:nth-child(1) {
  display: none;
}

.hover-only {
  opacity: 0%;
}

.minichart:hover .hover-only {
  opacity: 100%;
}

.indicator-table.comparison td {
  /* border: 1px solid green; */
}
.class_disabled {
  display: none;
}
</style>
