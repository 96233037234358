<script setup>
import { studyRegions } from "@/utils/utils";
</script>

<template lang="pug">
w-tooltip
  template(#activator="{ on }")
    w-button#download-button.mr2(v-on="on" icon="mdi mdi-cloud-download" color="primary" bg-color="white" elevation="2" xl)
  | Download data

w-menu.csv-downloader(custom overlay shadow activator="#download-button" )
  .white--bg
    .pa2 Download CSV
    h5.px2.pt2
      | Choose study region
    w-list.white--bg(v-model="selectedRegion" :items="items")



</template>

<script>
function download(url) {
  const a = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
export default {
  props: ["boundaryType", "year"],
  data: () => ({ selectedRegion: null }),
  created() {
    window.CsvDownloader = this;
  },
  computed: {
    downloadName() {
      const downloadName = `${this.boundaryType.replace(
        /^([^_]+)/,
        "$1_download"
      )}`;
      return `downloads/${downloadName}_${this.year}_${this.selectedRegion}.csv`;
    },
    items() {
      const items = Object.entries(studyRegions)
        .map(([k, v]) => {
          return { label: v, value: k };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      if (this.boundaryType.match(/lga|ssc/)) {
        items.unshift({ label: "All study regions", value: "all" });
      }
      return items;
    },
  },
  watch: {
    selectedRegion() {
      download(this.downloadName);
    },
  },
};
</script>

<style>
.csv-downloader .w-list {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
