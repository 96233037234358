<template lang="pug">
div
  .w-flex.justify-space-between(style="background:var(--w-primary-color)")
    w-select.title3.lh5(v-model="domain" :items="items" color="white" :bg-color="domain==='_census' ? 'orange' : 'primary'" no-unselect )
      template(#item="{ item, selected }")
        span.ml1 {{ item.label }}
        w-tag.ml2(bg-color="orange" xs v-if="item.value==='_census'") ABS
    div
      slot(name="top")

  w-select.title4.pl4.lh5(v-if="domain==='_census'" v-model="censusDomain" :items="censusDomains" color="orange" bg-color="white" no-unselect)
  .w-flex.justify-space-between
    w-select.grow.pt3.pl4.title5(v-model="indicator" :items="indicators" fit-to-content :color="domain === '_census' ? 'orange' : 'secondary'" :class="{ pl8: domain === '_census' }" no-unselect)
    .no-grow.pt4.pl4
      slot

</template>

<script setup>
import { ref, watch, computed, nextTick, onMounted } from "vue";
import metadata from "@/metadata";
import config from "@/config";
import { logEvent } from "@/api";
onMounted(() =>
  document
    .querySelectorAll("[contenteditable=true]")
    .forEach((el) => (el.contentEditable = false))
);
const props = defineProps({ boundaryType: String, suffix: String });
const emit = defineEmits(["update:indicator"]);

const domain = ref("Specialist Housing");
const censusDomain = ref("Labour force status");
const liveabilityDomains = computed(() =>
  [
    ...new Set(
      Object.entries(metadata.indicators)
        .filter(([k, i]) => i.source === "auo")
        .map(([value, entry]) => entry.observatory_domain)
    ),
  ].map((value) => ({
    value,
    label: value + (value === domain.value ? " " + props.suffix : ""),
  }))
);
const censusDomains = [
  ...new Set(
    Object.entries(metadata.censusIndicators).map(
      ([value, entry]) => entry.observatory_domain
    )
  ),
].map((value) => ({ value, label: `${value}` }));

const items = computed(() => [
  ...liveabilityDomains.value,
  {
    value: "_census",
    label:
      "Demographic data" +
      (domain.value === "_census" ? " " + props.suffix : ""),
  },
]);

window.metadata = metadata;
const indicator = ref("");
const indicators = computed(() => {
  const ret = [...Object.entries(metadata.indicators)]
    .filter(
      ([value, e]) =>
        e.observatory_domain ===
        (domain.value === "_census" ? censusDomain.value : domain.value)
    )
    .map(([value, entry]) => ({
      value,
      label: entry.observatory_description,
    }));

  return ret;
});

watch(indicators, (newVal) => {
  if (!newVal.find((i) => i.value === indicator.value)) {
    console.log(
      `${indicator.value} is not available for this boundary type. change to ${newVal[0]?.value}`
    );
    indicator.value = newVal[0]?.value;
  }
});

watch(indicator, (newVal) => {
  emit("update:indicator", newVal);
  logEvent("indicator-select", newVal);
});
watch(domain, (newVal) => {
  indicator.value = indicators.value[0]?.value;
  logEvent("domain-select", newVal);
});

watch(
  () => props.boundaryType,
  (newVal) => {}
);

window.eventBus.on("map:ready", () => {
  domain.value = config.firstDomain;
  indicator.value = config.firstIndicator;
});
</script>

<script>
export default {
  mounted() {
    window.IndicatorSelector = this;
  },
};
</script>
<style>
.w-app .title5 * {
  font-weight: normal;
}
</style>
