import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import WaveUI from "wave-ui";
import "wave-ui/dist/wave-ui.css";
import { createAuth0 } from "@auth0/auth0-vue";
import { env } from "@/getEnv";
import "./assets/main.css";

const app = createApp(App);
app.use(WaveUI, {
  colors: {
    primary: "#24c9ac",
    secondary: "#ff55a3",
  },
});

let auth;

try {
  auth = createAuth0({
    domain: env.domain,
    clientId: env.clientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: env.audience,
      scope: "openid profile email read:pro",
    },
  });

  app.use(auth);
} catch (e) {
  console.log("Couldn't setup auth.");
}
// app.use(router);

app.mount("#app");
