import { env } from "@/getEnv";
export async function safeAddTiles({ map, sourceId, url }) {
  // we do this extra check because Mapbox freezes up if we get a png (non-existent) tileset descriptor

  let sourceDef;
  try {
    sourceDef = await fetch(url, window.Mapping.authHeaders).then((r) =>
      r.json()
    );
  } catch (e) {
    console.error(`Problem with tiles at at ${url}`);
    return;
  }
  // console.log(sourceDef);
  if (sourceDef.format === "png") {
    throw `Tiles not available at ${url}`;
  }

  // Tessera thinks it's serving tiles at say /api/tiles/...{z}/{x}/{y}.pbf but they're actually at say /dev/api/tiles/...{z}/{x}/{y}.pbf
  const tileUrlWithEnv = (url) =>
    ["qa", "dev"].includes(env.site)
      ? url.replace(/\/api\/tiles/, env.tilesBase)
      : url;
  map.U.addVector(sourceId, {
    // url,
    tiles: sourceDef.tiles.map(
      (url) => tileUrlWithEnv(url) + `?dt=${Date.now()}`
    ),
    minzoom: sourceDef.minzoom,
    maxzoom: sourceDef.maxzoom,
    bounds: sourceDef.bounds,
    scheme: sourceDef.scheme,

    // ...sourceDef,
  });
}
