<script setup>
import "@mdi/font/css/materialdesignicons.min.css";
</script>

<template lang="pug">
.info-button
  w-menu(custom)
    template(#activator="{ on }")
      w-button(v-if="!image" v-on="on" icon="mdi mdi-information" color="blue" bg-color="transparent")
      w-button.icon-button(v-else v-on="on" bg-color="transparent")
        img(:src="image" style="width: 20px; height: 20px")


    .popup(v-html="text" v-if="text")
    .popup(v-else)
      slot

</template>
<script>
import "@mdi/font/css/materialdesignicons.min.css";

export default {
  props: ["text", "image"],
  data: () => ({}),
  created() {},
};
</script>

<style scoped>
.icon-button {
  border-radius: 50px;
  width: 27px;
  height: 30px;
}
</style>
<style>
.popup {
  max-width: 35em;
  line-height: 2;
  background: white;
  border-radius: 8px;
  padding: 2em 3em;
  box-shadow: hsla(0, 0%, 30%, 0.8) 2px 2px 4px 4px;
  border: 2px solid hsla(0, 0%, 0%, 0.2);
  max-height: 40em;
  overflow-y: auto;
}

.popup p + p {
  margin-top: 1.5em;
}

.popup li {
  margin-left: 1em;
}
</style>
