import * as mercator from "global-mercator";
import getVectorTile from "get-vector-tile";
import pointInPolygon from "@turf/boolean-point-in-polygon";
async function queryLngLat(
  uri,
  lngLat,
  zoom,
  layer,
  options = { quiet: false }
) {
  const [x, y, z] = mercator.lngLatToGoogle(lngLat, zoom);
  try {
    const featureCollection = await getVectorTile({ uri, x, y, z, layer });
    const features = featureCollection.features.filter((f) =>
      pointInPolygon(lngLat, f)
    );
    if (features) {
      return {
        type: "FeatureCollection",
        features,
      };
    }
  } catch (e) {
    if (!options.quiet) {
      console.error(e);
    }
  }
}
export default queryLngLat;
