<template lang="pug">
div
  h5.mt8 Overlays
  .w-flex.justify-space-between
    w-select.pt2.mr12(label= "Schools" v-model="layers.schools" :items="schoolOptions" no-unselect)
    w-select.pt2(label="Boundaries" v-model="layers.boundaries" :items="boundaryOptions" no-unselect )
  //- w-select.title3.lh5(v-model="domain" :items="[...liveabilityDomains, { label: 'Census', value: '_census' }]" color="white" :bg-color="domain==='_census' ? 'orange' : 'primary'")


</template>
<script>
import { logEvent } from "@/api";

export default {
  mounted() {
    window.OverlaySelector = this;
  },
  data: () => ({
    layers: {
      schools: "",
      boundaries: "",
    },
    schoolOptions: [
      "Combined P-12",
      "Primary",
      "Secondary",
      "Special",
      "All",
      "None",
    ].map((label) => ({
      label,
      value: label,
    })),
    boundaryOptions: [
      { label: "City", value: "city" },
      { label: "Suburb", value: "ssc" },
      { label: "LGA", value: "lga" },
      { label: "None", value: "" },
    ],
  }),
  watch: {
    layers: {
      deep: true,
      handler() {
        this.$emit("changeOverlays", this.layers);
        logEvent("overlay-select", "", this.layers);
      },
    },
  },
};
</script>
<style>
.w-app .title5 * {
  font-weight: normal;
}
</style>
