<script setup>
import { Select } from "flowbite-vue";
import { ref, watch, defineEmits, computed } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import config from "@/config";
import { logEvent } from "@/api";

const emit = defineEmits(["update:boundaryType"]);
const TEMP = true;
const options = [
  { label: "2018", value: "2018" },
  { label: "2021", value: "2021" },
  { label: "2021 vs 2018", value: "2018,2021" },
];

const year = ref(config.firstYear);

watch(year, (newVal) => {
  emit("update:boundaryYear", newVal);
  logEvent("boundary-year-select", newVal);
});

window.eventBus.on("set-comparison-mode", (mode) => {
  if (mode === "single") {
    year.value = "2021";
  }
});
</script>

<template lang="pug">
.year-selector
  w-select(v-model="year" :items="options" label="Year" no-unselect style="width:8em")

</template>
