import { env } from "@/getEnv";
import { safeAddTiles } from "./safeAddTiles";
import mapboxgl from "mapbox-gl";
export async function addBoundaryOverlays({ map }) {
  const promises = [];
  for (const year of [/*"2018", */ "2021"]) {
    for (const boundary of ["lga", "ssc", "city"]) {
      const sourceId = `boundaryOverlays-${boundary}-${year}`;

      const tileset = {
        lga: `lga_${year}/vampire`,
        ssc: `ssc_free_${year}/vampire`,
        city: `city_${year}`, // TODO what are we doing for city?
      }[boundary];
      const textField = {
        lga: ["get", "lga"],
        ssc: ["get", "suburb"],
        city: ["get", "study_region"],
      }[boundary];
      const minzoom = {
        lga: 10,
        ssc: 13,
        city: 8,
      }[boundary];

      let sourceLayer;
      if (boundary === "lga" || boundary === "ssc") {
        sourceLayer = "vampire";
      } else sourceLayer = boundary;
      promises.push(
        safeAddTiles({
          map,
          url: `${env.tilesBase}/${tileset}/index.json`,
          sourceId,
        }).then(() => {
          map.U.addLine(`${sourceId}-line`, sourceId, {
            sourceLayer,
            lineColor: "white",
            lineWidth: 3,
            visibility: "none",
          });
          map.U.addLine(`${sourceId}-line-inner`, sourceId, {
            sourceLayer,
            lineColor: "black",
            lineWidth: 2,
            visibility: "none",
          });
          !env.offline &&
            boundary === "lga" &&
            map.U.addSymbol(`${sourceId}-label`, sourceId, {
              sourceLayer,
              textField,
              textSize: ["interpolate", ["linear"], ["zoom"], 10, 12, 16, 16],
              symbolPlacement: "line",
              symbolSpacing: 100,
              textOffset: [0, 0.5],
              minzoom,
              visibility: "none",
            });
        })
      );
    }
  }
  return Promise.all(promises);
}

export function updateBoundaryOverlays({ map, filter }) {
  for (const boundary of ["lga", "ssc", "city"]) {
    map.U.toggle(
      new RegExp(`boundaryOverlays-${boundary}`),
      filter.boundaries === boundary
    );
  }
}
