<template lang="pug">
.white--bg(style="position:absolute;width:100%; height:100%")
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "@/config";
import U from "map-gl-utils/noflow/index";
import { getPerms, authHeaders, logEvent } from "@/api";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { tilesetForIndicator } from "@/utils/utils";
import syncMap from "mapbox-gl-sync-move";
// import { queryMbtiles } from "query-remote-tiles";
import queryRemoteTiles from "@/queryRemoteTiles";
import { addIndicatorLayers } from "./mapLayer/indicator";
import { addSchoolOverlays, updateSchoolOverlays } from "./mapLayer/schools";
import {
  addBoundaryOverlays,
  updateBoundaryOverlays,
} from "./mapLayer/boundaryOverlays";
import { env } from "@/getEnv";

// import { EventBus } from "../EventBus";
window.U = U;
export default {
  props: [
    "boundaryTypeYear",
    "indicator",
    "container",
    "overlays",
    "settingIsochrone",
  ],
  data: () => ({
    prevSource: null,
  }),
  created() {
    window[this.container === "map2" ? "Mapping2" : "Mapping"] = this;
  },
  async mounted() {
    // debugger;
    if (this.container === "map") window.Mapping = this;
    if (this.container === "map2") window.Mapping2 = this;
    mapboxgl.accessToken =
      "pk.eyJ1Ijoic3RldmFnZSIsImEiOiJjbGpkdThhdHEwNDF0M2VrNjBwZHdhd3JxIn0.LZ0LdcFQx21HLNxWOSfGWQ";
    const map = new mapboxgl.Map({
      container: this.container,
      center:
        this.container === "map2" ? window.map.getCenter() : [144.96, -37.81],
      zoom: this.container === "map2" ? window.map.getZoom() : 11,
      // attributionControl: false,
      customAttribution: [
        "Australian Urban Observatory © <a href='http://cur.org.au/research-programs/healthy-liveable-cities-group/' class='primary'>RMIT 2024</a>",
      ],
      style: env.offline
        ? { version: 8, layers: [], sources: {} }
        : "mapbox://styles/mapbox/light-v9",

      transformRequest: (url, resourceType) => {
        if (/mapbox/.test(url)) {
          return { url };
        }
        return {
          url,
          ...this.authHeaders,
        };
      },
    });

    this.map = map;
    window[this.container] = map;

    U.init(map, mapboxgl);
    map.U.onLoad(async () => {
      window.eventBus.emit("map:ready", null, {});
      if (this.container === "map2") {
        // debugger;
        syncMap(window.map, window.map2);
      }

      this.switchLayer();
      addSchoolOverlays({ map });
      await addBoundaryOverlays({ map });
      map.on("click", `boundary-fill`, this.clickBoundaryHandler.bind(this));

      if (this.container === "map") {
        // map.addControl(
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: true,
          clearOnBlur: true,
          types: "region,postcode,district,place,locality,neighborhood,address",
          countries: "au",
          placeholder: "Address search",
        });
        document
          .getElementById("geocoder-container")
          .appendChild(geocoder.onAdd(map));
        // .appendChild(geocoder.onAdd(map));
        // geocoder.addTo("#geocoder-container");
        // geocoder.onAdd(map);
        geocoder.on("result", (result) => {
          const { center } = result.result;
          // map.flyTo({ center, zoom: 12 });
        });
        geocoder.on("loading", () => {
          const isAddress = geocoder.inputString.match(/\d/i);
          geocoder.options.types =
            "region,postcode,district,place,locality,neighborhood" +
            (isAddress ? ",address" : "");
        });
        window.geocoder = geocoder;
      } else {
        if (window.Mapping.lastClick) {
          map.once("idle", () => {
            this.clickMap(window.Mapping.lastClick);
          });

          // this.clickMap(window.Mapping.lastClick);
        }
      }

      window.eventBus.on("click-map", (e) => this.clickMap(e));
    });
    map.on("zoom", () => {
      window.eventBus.emit("map:zoom", null, map.getZoom());
    });

    map.addControl(new mapboxgl.ScaleControl({ maxWidth: 300 }));
  },
  methods: {
    // init(map) {
    //   this.switchLayer({
    //     boundaryType: config.firstBoundaryType,
    //     indicator: config.firstIndicator,
    //   });
    // },
    async addBoundaryTypeYearLayers(options) {
      const permFilter = await this.permFilterForBoundaryType(
        options.boundaryTypeYear
      );
      addIndicatorLayers({ ...options, permFilter });
    },
    async permFilterForBoundaryType(boundaryTypeYear) {
      const boundaryType = boundaryTypeYear.replace(/_\d+$/, "");
      const perms = await getPerms();
      return perms[boundaryType] || true;
    },
    async switchLayer() {
      const map = this.map;
      if (this.mouseHandler) {
        map.off("mousemove", this.mouseHandler);
        // map.off("click", "boundary-fill", this.clickHandler);
        this.mouseHandler = undefined;
        // this.clickHandler = undefined;
      }
      map.U.removeSource(this.prevSource);

      this.oldFeature = null;
      this.prevSource = this.currentSource;
      this.authHeaders = await authHeaders();
      await this.addBoundaryTypeYearLayers({
        map: map,
        boundaryTypeYear: this.boundaryTypeYear,
        indicator: this.indicator,
        sourceId: this.currentSource,
        tileset: this.tileset,
      });
      if (this.lastClick) {
        this.map.once("sourcedata", () => {
          this.clickMap(this.lastClick);
        });
      }

      this.mouseHandler = (e) => {
        if (!map.getStyle()?.layers.find((l) => l.id === "boundary-fill")) {
          return;
        }
        if (this.settingIsochrone) {
          return;
        }
        const [feature] = map.queryRenderedFeatures(e.point, {
          layers: ["boundary-fill"],
        });
        if (this.oldFeature) {
          map.setFeatureState(this.oldFeature, { hover: false });
          this.oldFeature = null;
        }
        if (feature) {
          map.getCanvas().style.cursor = "pointer";
          if (feature.id) {
            map.setFeatureState(feature, { hover: true });
            this.oldFeature = feature;
          }
        } else {
          map.getCanvas().style.cursor = "";
        }
      };
      map.on("mousemove", this.mouseHandler);
    },
    clickBoundaryHandler(e) {
      if (this.settingIsochrone) {
        return;
      }
      // send event so other map can be clicked at same point.
      window.eventBus.emit("click-map", null, e);
      const f = e.features[0];
      console.log(f.properties);

      this.$emit("select-boundary", f.properties);

      let id = `lga:${f.properties.lga}`;
      if (f.properties.sa1) {
        id = `sa1:${f.properties.sa1}`;
      } else if (f.properties.ssc) {
        id = `ssc:${f.properties.ssc}`;
      }
      logEvent("boundary-select", id);
    },
    setSelectedBoundary(feature) {
      if (
        this.clickedFeature?.id &&
        this.map.getStyle().sources[this.clickedFeature.source]
      ) {
        this.map.setFeatureState(this.clickedFeature, { clicked: false });
      }
      this.clickedFeature = feature;

      if (this.clickedFeature.id) {
        this.map.setFeatureState(this.clickedFeature, { clicked: true });
      }
    },
    async clickMap(e) {
      this.lastClick = e;
      const tilesUrl = this.map.getStyle().sources[this.currentSource].tiles[0];
      const source = this.currentSource;
      const res = await queryRemoteTiles(
        tilesUrl,
        e.lngLat.toArray(),
        11,
        this.tileset
      );
      const selectedBoundary = res.features[0];
      this.$emit("select-boundary", selectedBoundary.properties);
      this.setSelectedBoundary({
        ...selectedBoundary,
        source,
        sourceLayer: this.indicator,
      });
    },
  },
  computed: {
    currentSource() {
      return `${this.boundaryTypeYear}${this.tileset}`;
    },
    tileset() {
      return tilesetForIndicator(this.indicator);
    },
  },
  watch: {
    currentSource() {
      this.switchLayer();
    },
    indicator() {
      this.switchLayer();
    },
    overlays: {
      deep: true,
      handler() {
        updateSchoolOverlays({ map: this.map, filter: this.overlays.schools });
        updateBoundaryOverlays({ map: this.map, filter: this.overlays });
      },
    },
    settingIsochrone() {
      if (this.settingIsochrone) {
        this.map.getCanvas().style.cursor = "crosshair";
      } else {
        this.map.getCanvas().style.cursor = "";
      }
    },
  },
};
</script>

<style>
.mapboxgl-ctrl-geocoder--powered-by,
.mapbox-improve-map {
  display: none !important;
}

.school-popup .mapboxgl-popup-content {
  padding: 4px 2px;
  background: hsla(0, 0%, 100%, 0.7);
}
</style>
