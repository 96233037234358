<template lang="pug">
#BasemapSelector

    div.pa1.mb1.options.lightgray--bg(:class="{ 'options-open': open }")
        div.option.pointer.pv1.ph2(v-for="(id, label,) in basemaps" :value="id" @click="basemap = id; open = false")
            span(style="display:inline-block;width: 12px") {{ id === basemap ? '&check;' : '' }}
            span {{ label }}
    button.sh4.pa1.bg-white( @click.prevent="open=!open" )
        img(src="@/assets/map.png" style="width: 20px; height:20px;")

</template>

<script>
import mapboxgl from "mapbox-gl";
import { logEvent } from "@/api";
export default {
  name: "BasemapSelector",

  props: ["mapId"],
  data: () => ({
    basemaps: {
      Streets: "mapbox/light-v9",
      // Dark: "mapbox/dark-v9",
      // Colour: "mapbox/streets-v11",
      Satellite: "mapbox/satellite-streets-v11",
      // Satellite: "mapbox/satellite-v9",
    },
    basemap: "mapbox/light-v9",
    open: false,
  }),
  created() {
    window.BasemapSelector = this;
  },
  mounted() {
    document
      .querySelector("#BasemapSelector button")
      .addEventListener("blur", async (e) => {
        if (e.relatedTarget) {
          this.open = false;
        }
      });
  },
  watch: {
    basemap() {
      switchBasemap(window[this.mapId], this.basemap);
    },
  },
};

async function switchBasemap(map, styleID) {
  if (styleID) {
    window.localStorage.setItem("basemap", styleID);
  }
  const url = `https://api.mapbox.com/styles/v1/${styleID}?access_token=${mapboxgl.accessToken}`;

  const newStyle = await window.fetch(url).then((res) => res.json());

  // our custom generated sprite via update-sprite.sh
  // newStyle.sprite =
  // window.location.origin + window.location.pathname + "sprite/sprite";
  let currentStyle = map.getStyle();
  if (!currentStyle) {
    map.setStyle(newStyle);
    return;
  }

  // ensure any sources from the current style are copied across to the new style
  newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources);

  // find the index of where to insert our layers to retain in the new style
  let labelIndex = newStyle.layers.findIndex((el) => {
    return el.id == "notwaterway-label";
  });

  // default to on top
  if (labelIndex === -1) {
    labelIndex = newStyle.layers.length;
  }
  const appLayers = currentStyle.layers.filter((el) => {
    // app layers are the layers to retain, and these are any layers which have a different source set
    return (
      el.source &&
      el.source != "mapbox://mapbox.satellite" &&
      el.source != "mapbox" &&
      el.source != "composite"
    );
  });
  newStyle.layers = [
    ...newStyle.layers.slice(0, labelIndex),
    ...appLayers,
    ...newStyle.layers.slice(labelIndex, -1),
  ];
  map.setStyle(newStyle);
  logEvent("basemap-switch", styleID);
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.option:hover {
  background: #ccc;
}
.option {
  padding: 4px;
}
.options {
  opacity: 0;
  transition: opacity 0.3s;
  /* display: none; */
}
.options-open {
  opacity: 1;
  transition: opacity 0.3s;
  display: block;
  background: lightgray;
}

#BasemapSelector {
  text-align: right;
}

#BasemapSelector * {
  text-align: left;
}
</style>
