export function getEnv() {
  if (window.location.hostname === "localhost") {
    return window.location.hash.match(/prod/) ? "prod" : "local";
  } else {
    const envString = window.location.pathname.match(/^\/(qa|dev)\//)?.[1];
    return envString ?? "prod";
  }
}
export const env = {
  local: {
    site: "local",
    domain: "dev-6xhr86zjsduy0n88.us.auth0.com",
    // domain: "dev-kybm4p727ulc1mnb.us.auth0.com",
    clientId: "NsJGnT2FAsGgjZktkjhmYnN1ame4rwas",
    // clientId: "1I6ifUWHNEm4NRNzKnj65YcTnbqB3X1e",
    audience: "AUO",
    // audience: "https://dev-kybm4p727ulc1mnb.us.auth0.com/api/v2/",
    apiBase: "http://localhost:4005/api",
    tilesBase: "http://localhost:4005/api/tiles",
    // "AAPKa306acdf644f4dd49f9a31898f521d3ap9-7VR6Ky1_zPeaGDNrY0pntOkV_9tFWLbgAsw8NlSBC7tasjwEJ4-yIlJQ_gU4n",
    arcgisApiToken:
      "AAPKfe9034a8d81e4205be689584aa9baa5aFncnKEZj8-Qq8wLFa7RWuWQlQASt3ifteNZufYHBUQhyHjKfo6lumcdqImJ6cCQt",
    // offline: true,
  },
  prod: {
    site: "prod",
    domain: "auo.au.auth0.com",
    clientId: "KbciCnIwyLhx0ODaAqDLt8qB1jPcEguy",
    audience: "auo-backend-prod",
    apiBase: "/api",
    tilesBase: "/api/tiles",
    arcgisApiToken:
      "AAPKfe9034a8d81e4205be689584aa9baa5aFncnKEZj8-Qq8wLFa7RWuWQlQASt3ifteNZufYHBUQhyHjKfo6lumcdqImJ6cCQt",
  },
  qa: {
    site: "qa",
    domain: "auo.au.auth0.com",
    clientId: "KbciCnIwyLhx0ODaAqDLt8qB1jPcEguy",
    audience: "auo-backend-prod",
    apiBase: "/qa/api",
    tilesBase: "/qa/api/tiles",
    arcgisApiToken:
      "AAPKfe9034a8d81e4205be689584aa9baa5aFncnKEZj8-Qq8wLFa7RWuWQlQASt3ifteNZufYHBUQhyHjKfo6lumcdqImJ6cCQt",
  },
  dev: {
    site: "dev",
    domain: "auo.au.auth0.com",
    clientId: "KbciCnIwyLhx0ODaAqDLt8qB1jPcEguy",
    audience: "auo-backend-prod",
    apiBase: "/dev/api",
    tilesBase: "/dev/api/tiles",
    arcgisApiToken:
      "AAPKfe9034a8d81e4205be689584aa9baa5aFncnKEZj8-Qq8wLFa7RWuWQlQASt3ifteNZufYHBUQhyHjKfo6lumcdqImJ6cCQt",
  },
}[getEnv()];
