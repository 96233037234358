<script setup></script>

<template lang="pug">
#IsochroneTool.my6
  h5 Walking distance
  w-flex.justify-space-between
    w-button.z0(v-if="!setting && !results" outline @click="startSetting") Set centre
    div(v-if="!setting && !results" style="width:150px")
      w-select(v-model="walkMinutes" :items="travelTimeOptions" label="Walking time" fit-to-content no-unselect)
  p.b(v-if="setting") Click on the map to set the travel time centre
  w-button.z0(v-if="setting" text @click="cancel" style="display:inline-block") Cancel
  div(v-if="results")
    p.mt8.mb2 Population within {{ walkMinutes }} minutes' walk from the marked location:
    table.results(v-if="results")
      tr
        th Total population
        td {{ results.TOTPOP.toLocaleString() }}
      //- tr
      //-   th Total households
      //-   td {{ results.TOTHH.toLocaleString() }}
      tr
        th Male
        td {{ results.TOTMALES.toLocaleString() }}
      tr
        th Female
        td {{ results.TOTFEMALES.toLocaleString() }}
      tr
        th Average household size
        td {{ results.AVGHHSZ.toLocaleString() }}
  w-button(v-if="center" @click="clear" outline) Clear

</template>

<script>
import mapboxgl from "mapbox-gl";
import { env } from "@/getEnv";
import { logEvent } from "@/api";

/*
https://developers.arcgis.com/documentation/mapping-apis-and-services/demographics/services/geoenrichment-service/
*/
export default {
  data: () => ({
    setting: false,
    results: undefined,
    center: undefined,
    walkMinutes: 10,
    travelTimeOptions: [10, 15, 20, 30, 45, 60].map((n) => ({
      label: `${n} mins`,
      value: n,
    })),
  }),
  created() {
    window.IsochroneTool = this;
  },
  methods: {
    startSetting() {
      this.setting = true;
      this.mapClickHandler = (e) => {
        this.setting = false;
        this.mapClickHandler = null;
        this.$emit("changeSettingIsochrone", false);
        this.setIsochrone(e.lngLat);
      };
      window.map.once("click", this.mapClickHandler);
      this.$emit("changeSettingIsochrone", true);
    },
    cancel() {
      this.setting = false;
      if (this.mapClickHandler) {
        window.map.off(this.mapClickHandler);
        this.mapClickHandler = null;
      }
      this.$emit("changeSettingIsochrone", false);
    },
    clear() {
      this.results = null;
      this.center = null;
      map.U.removeSource("isochrone");
      map.U.removeSource("isochrone-center");
    },
    async setIsochrone(center) {
      this.center = center;
      const map = window.map;
      const mode = "walking";
      const url = `https://api.mapbox.com/isochrone/v1/mapbox/${mode}/${center.toArray()}?contours_minutes=${
        this.walkMinutes
      }&polygons=true&access_token=${mapboxgl.accessToken}`;
      const data = await fetch(url).then((res) => res.json());

      map.U.addGeoJSON("isochrone", data);
      map.U.addFill("isochrone-fill", "isochrone", {
        fillColor: "red",
        fillOpacity: 0.3,
      });
      map.U.addLine("isochrone-line", "isochrone", {
        lineColor: "red",
        lineWidth: 2,
      });
      map.U.addGeoJSON("isochrone-center", {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: center.toArray(),
        },
      });
      map.U.addCircle("isochrone-center-circle", "isochrone-center", {
        circleRadius: 3,
        circleColor: "red",
        circleFill: "red",
      });
      const populationData = await this.getPopulationForIsochrone(
        data.features[0]
      );
      this.results = populationData;
      logEvent("isochrone-set", this.walkMinutes);
    },
    async getPopulationForIsochrone(feature) {
      const url =
        "https://geoenrich.arcgis.com/arcgis/rest/services/World/geoenrichmentserver/GeoEnrichment/enrich?" +
        new URLSearchParams({
          StudyAreas: JSON.stringify([
            { geometry: this.geoJsonToArcgis(feature) },
          ]),
          f: "geojson",
          token: env.arcgisApiToken,
        });
      const response = await fetch(url).then((res) => res.json());

      if (response.error) {
        this.error = response.error.message;
      } else {
        return response.results[0].features[0].properties;
      }
    },
    // convert a GeoJSON polygon into the ArcGIS json format
    geoJsonToArcgis(feature) {
      const ring = [...feature.geometry.coordinates[0]];
      ring.reverse();
      return {
        rings: [ring],
        spatialReference: { wkid: 4326 },
      };
    },
  },
};
</script>

<style scoped>
.results th {
  text-align: right;
  padding-right: 8px;
  padding-top: 4px;
}

.z0 {
  z-index: 0;
}
</style>
