<script setup>
import { Select } from "flowbite-vue";
import { ref, watch, computed } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { logEvent, getPerms } from "@/api";
import config from "@/config";

const emit = defineEmits(["update:boundaryType"]);
const TEMP = true;

const isLoggedIn = computed(() => window.App?.$auth0?.isAuthenticated?.value);

const perms = ref({});
window.eventBus.on("update:perms", (newPerms) => {
  perms.value = newPerms;
});

const options = computed(() => {
  if (isLoggedIn) {
    return [
      { value: "lga", label: "LGA (free)" },
      { value: "ssc_free", label: "Suburb (free)" },
      { value: "sa1_free", label: "Neighbourhood (free)" },
      ...(perms.value.ssc_pro
        ? [{ value: "ssc_pro", label: "Suburb (pro)" }]
        : [{ value: "ssc_pro", label: "Suburb (pro)", disabled: true }]),
      ...(perms.value.sa1_pro
        ? [{ value: "sa1_pro", label: "Neighbourhood (pro)" }]
        : [{ value: "sa1_pro", label: "Neighbourhood (pro)", disabled: true }]),
    ];
  } else {
    return [
      { value: "lga", label: "LGA" },
      { value: "ssc_free", label: "Suburb" },
      { value: "sa1_free", label: "Neighbourhood" },
    ];
  }
});
const boundary = ref(config.firstBoundaryType);

watch(isLoggedIn, async (newVal) => {
  if (newVal) {
    boundary.value = config.firstBoundaryType;
  } else {
    boundary.value = "lga";
  }
});

watch(boundary, (newVal) => {
  window.Mapping.switchLayer({ boundaryType: newVal });
  emit("update:boundaryType", newVal);

  logEvent("boundaryType-select", newVal);
});
</script>

<template lang="pug">
div
  //- Select(v-model="boundary" :options="options")
  w-select(v-model="boundary" :items="options" label="Boundary type" no-unselect)

</template>

<style scoped></style>
