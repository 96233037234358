import { tilesetForIndicator } from "@/utils/utils";
import * as colorRamps from "@/colorRamps";

import { env } from "@/getEnv";
import { safeAddTiles } from "./safeAddTiles";

export async function addIndicatorLayers({
  map,
  boundaryTypeYear,
  indicator,
  sourceId,
  tileset,
  permFilter,
}) {
  const addIndicatorSource = async () => {
    const url = `${env.tilesBase}/${boundaryTypeYear}/${tilesetForIndicator(
      indicator
    )}/index.json`;
    await safeAddTiles({ map, url, sourceId });

    window.setTimeout(() => {
      try {
        map.getSource(indicator).tiles = [
          map.getSource(indicator).tiles[0] + `?dt=${Date.now()}`,
        ];
      } catch (e) {}
    }, 200);
  };
  const addIndicatorFill = () => {
    const colors = colorRamps.colorsForIndicator(indicator);

    map.U.addFill(`boundary-fill`, sourceId, {
      sourceLayer: tileset,
      fillOutlineColor: "transparent",
      fillColor: U.interpolate(
        ["to-number", ["get", `p_${indicator}`]],
        ...colors.flatMap((c, i) => [i * 10, c])
      ),
      fillOpacity: U.zoom({ 6: 1, 9: 0.5, 15: 0.2 }),
      filter: permFilter,
    });

    // Handle NA case
    map.setPaintProperty("boundary-fill", "fill-color", [
    "match",
    ["get", `p_${indicator}`],
    "NA",
    "rgb(200, 200, 200)", // Color for NA values
    U.interpolate(
      ["to-number", ["get", `p_${indicator}`]],
      ...colors.flatMap((c, i) => [i * 10, c])
    ),
  ]);

    map.U.addLine(`boundary-border`, sourceId, {
      sourceLayer: tileset,
      lineWidth: 1,
      lineColor: "hsla(0,0%,30%,0.3)",

      lineJoin: "round",
      minzoom: boundaryTypeYear.match(/^sa1/)
        ? 11
        : boundaryTypeYear.match(/^ssc/)
        ? 9
        : 9,
      filter: permFilter,
    });
    map.U.addLine(`boundary-border-state`, sourceId, {
      sourceLayer: tileset,
      lineWidth: [
        "case",
        ["to-boolean", ["feature-state", "clicked"]],
        5,
        ["case", ["to-boolean", ["feature-state", "hover"]], 3, 0],
      ],
      lineColor: [
        "case",
        ["to-boolean", ["feature-state", "clicked"]],
        "black",
        [
          "case",
          ["to-boolean", ["feature-state", "hover"]],
          "#555",
          "hsla(0,0%,30%,0.3)",
        ],
      ],
      lineJoin: "round",

      filter: permFilter,
    });
    if (boundaryTypeYear.match(/lga/)) {
      !env.offline &&
        map.U.addSymbol(`${sourceId}-label-center`, sourceId, {
          sourceLayer: tileset,
          textField: ["get", "lga"],
          textSize: ["interpolate", ["linear"], ["zoom"], 10, 10, 16, 16],
          // symbolPlacement: "line",
          symbolSpacing: 100,
          textOffset: [0, 0.5],
          minzoom: 10,
          textColor: "hsla(0, 0%, 10%, 0.5)",
          // visibility: "none",
        });
    }
  };

  await addIndicatorSource();
  addIndicatorFill();
}
