/*
We have to load the script directly due to lack of Vite support
https://github.com/foliojs/pdfkit/issues/1413
*/
// import PDFDocument from "pdfkit";

const PDFDocument = window.PDFDocument;
window.PDF = PDFDocument;
import blobStream from "blob-stream";
import { th } from "@/utils/utils";
window.Buffer = Buffer;
let poppins, poppinsBold;
async function loadImageToBuffer(url) {
  try {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  } catch (error) {
    console.error("Error loading image:", error);
    throw error; // Re-throw the error for the caller to handle if needed
  }
}

function miniChart({ doc, width, height, row, first }) {
  let [x, y] = [doc.x, doc.y];
  doc
    .moveTo(x, y + height / 2)
    .lineTo(x + width, y + height / 2)
    .stroke("#777");

  doc
    .moveTo(x + width / 2, y)
    .lineTo(x + width / 2, y + height)
    .stroke("#777");

  doc
    .circle(x + (+row.percentile / 100) * width, y + height / 2, 5)
    .fillAndStroke(row.color, "#333");
  doc.fill("#000");
}

let tableWidths;
function addTable({ doc, rows, widths = tableWidths, extraRowHeight = 0 }) {
  rows.forEach((row) => {
    addRow({ doc, row, widths });
    doc.y += extraRowHeight;
  });
}

function addRow({ doc, row, widths = tableWidths }) {
  tableWidths = widths;
  let oldx = doc.x;
  let oldy = doc.y;
  let oldFontSize = doc._fontSize;
  // let oldFillColor = [...doc._fillColor];
  // doc.x = 50;
  let tallestCell = 0;
  row.forEach((text, i) => {
    if (!text) return;
    doc._fontSize = oldFontSize;
    // doc._fillColor = [...oldFillColor];
    doc.fillColor("black");
    if (i === 0) {
      doc.font(poppinsBold);
    } else {
      doc.font(poppins);
    }
    const options = {
      width: widths[i],
    };
    doc.y = oldy;
    if (typeof text === "function") {
      text(options);
    } else {
      doc.text(text, options);
    }
    doc.x += widths[i];
    tallestCell = Math.max(tallestCell, doc.y - oldy);
  });
  doc.y = oldy + tallestCell + doc.currentLineHeight();
  doc.x = oldx;
}

async function scorecardRows({ doc, scorecard }) {
  const rowHeight = doc.heightOfString(" ");

  return Promise.all(
    scorecard.map(async (row, rowNum) => {
      const iconBuffer = await loadImageToBuffer(row.icon);

      return [
        () => doc.image(iconBuffer, { fit: [rowHeight, rowHeight] }),
        (options) => {
          doc.text(row.label, options);
          doc.fillColor("#777");
          doc.fontSize(8);
          row.sub && doc.text(row.sub, options);
        },
        row.valueFormatted,

        () =>
          miniChart({
            doc,
            width: 100,
            height: doc.heightOfString(" "),
            row,
            first: rowNum === 0,
          }),
      ];
    })
  );
}

export default async function makePdf({
  selectedBoundaries,
  indicators,
  identifiers,
  metadatas,
  values,
  percentiles,
  scorecard,
}) {
  let y = 0;
  const doc = new PDFDocument();
  const stream = doc.pipe(blobStream());

  const auoLogo = await loadImageToBuffer(
    "RMIT%20AUO_Brandmark_Horizontal_pos_RGB.png"
  );
  const topMargin = 40;
  doc.image(auoLogo, doc.page.width - 100 - 50, topMargin - 10, { width: 100 });

  poppins = await fetch("fonts/poppins.ttf").then((r) => r.arrayBuffer());
  poppinsBold = await fetch("fonts/poppins-bold.ttf").then((r) =>
    r.arrayBuffer()
  );
  doc.font(poppins);
  // debugger;
  doc.y = topMargin;
  const mainFontSize = 10;
  doc.fontSize(mainFontSize);
  const boundaryTableWidths =
    indicators.length > 1 ? [100, 200, 200] : [150, 400, 0];
  addTable({ doc, rows: identifiers, widths: boundaryTableWidths });

  doc.fontSize(mainFontSize);
  addRow({
    doc,
    row: [
      "Indicator",
      metadatas[0]?.observatory_description,

      metadatas[1]?.observatory_description !==
        metadatas[0]?.observatory_description &&
        metadatas[1]?.observatory_description,
    ],
    widths: boundaryTableWidths,
  });
  doc.moveTo(200, doc.y);

  addTable({ doc, rows: [values], widths: boundaryTableWidths });
  addTable({ doc, rows: percentiles });

  doc.y += 15;

  doc
    .fontSize(18)
    .text("Australian Urban Observatory Liveability Scorecard")
    .fontSize(mainFontSize);
  const iconWidth = doc.heightOfString(" ");
  doc.y = doc.y + iconWidth;

  addTable({
    doc,
    widths: [iconWidth * 2, 200, 100],
    rows: await scorecardRows({ doc, scorecard }),
    extraRowHeight: 6,
  });

  const dateDMY = new Date().toLocaleDateString("en-AU");

  doc.y = 708; // bottom of page
  doc
    .fontSize(8)
    .text(
      `Australian Urban Observatory @ RMIT University auo.org.au, ${dateDMY} DOI: 10.25956/5dcb85fa3bdfc`
    );
  doc.end();
  window.doc = doc;
  stream.on("finish", function () {
    const url = stream.toBlobURL("application/pdf");
    window.open(url, "pdf");
  });
}
