<script setup></script>

<template lang="pug">
#IndicatorMiniChart.minichart(style="position:relative; min-height:40px; width: 100%;")
    span(v-if="first" style="position:absolute; text-align:center;top:calc(50% - 2.5em); width: 100%; color: #555; font-size:12px") Median

    .x-axis
    .median
    .marker(:style="{ left: percentile + '%',background:color}")
    .percentile-label.hover-only {{ percentileth }} percentile
</template>

<script>
export default {
  props: ["percentile", "color", "first", "percentileth"],

  data: () => ({}),
  created() {
    window.IndicatorMiniChart = this;
  },
};
</script>

<style scoped>
.minichart .hover-only {
  transition: opacity 0.2s;
  opacity: 0%;
}

.minichart:hover .hover-only {
  opacity: 100%;
}

.marker {
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  border-radius: 44px;
  border: 1px solid #333;
  transition: transform 0.2s;
}

.minichart:hover .marker {
  transform: scale(150%);
}

.median {
  left: 50%;
  width: 1px;
  position: absolute;
  top: calc(50% - 5px);
  height: 10px;
  background: #999;
}

.x-axis {
  left: 0;
  right: 0;
  position: absolute;
  top: calc(50% - 1px);
  height: 1px;
  background: #999;
}

.minichart:hover .x-axis {
  background: #333;
}

.percentile-label {
  position: absolute;
  top: calc(50% + 0.5em);
  font-size: 12px;
  color: #555;
  text-align: center;
  width: 100%;
  /* transition: color 0.2s; */
}
</style>
