<template>
  <div v-if="isAuthorized">
    <h3>Upload CSV File</h3>
    <form @submit.prevent="uploadCsv">
      <input type="file" ref="fileInput" accept=".csv" />
      <button type="submit">Upload</button>
    </form>
    <button @click.prevent="restartApplication">Restart Application</button>
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { ref } from 'vue';
import { isAdmin, queryApi } from '@/api.js';

export default {
  name: 'CsvUpload',
  data() {
    return {
      message: '',
      isAuthorized: false
    };
  },
  methods: {
    async checkAuthorization() {
      try {
        this.isAuthorized = await isAdmin();
      } catch (error) {
        console.error("Error checking authorization: ", error);
        this.isAuthorized = false;
      }
    },
    async uploadCsv() {
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0];

      if (!file) {
        alert('Please select a CSV file to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await queryApi('upload-csv', {
          method: 'POST',
          body: formData
        });
        this.message = response.message || 'File uploaded successfully!';
      } catch (error) {
        this.message = 'Error: ' + error.message;
      }
    },
    async restartApplication() {
      console.log("Restarting application...");
      try {
        const response = queryApi('restart', {
          method: 'GET'
        });

        if (!response.ok) {
          throw new Error('Failed to restart application');
        }

        const message = await response.text();
        console.log(message);
        this.message = "Refresh the page.";
      } catch (error) {
        console.error('Error:', error);
        this.message = 'Refresh the page.';
      }
    }
  },
  mounted() {
    this.checkAuthorization();
    window.eventBus.on("update:admin", this.checkAuthorization);
  },
  beforeUnmount() {
    window.eventBus.off("update:admin", this.checkAuthorization);
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
